"use client";

import { LoadingBar } from "nftx-core";

export default function Loading() {
  return (
    <>
      <LoadingBar />
    </>
  );
}
